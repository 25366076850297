import React from "react";

export const AlertModal = (props) => {
  const { children, open, onClose, width = "small-width", height = "" } = props;
  // width: small-width medium-width full-width

  let activeClass = "";
  if (open) {
    activeClass = "is-active";
  }
  let modalContentClassWidth = "";
  if (width) {
    modalContentClassWidth = `modal-content--${width}`;
  }

  let modalContentClassHeight = "";
  if (height) {
    modalContentClassHeight = `modal-content--${height}`;
  }

  return (
    <div className={`modal ${activeClass} modal--high-priority`}>
      <div className="modal-background" onClick={onClose}></div>
      <div
        className={`modal-content ${modalContentClassWidth} ${modalContentClassHeight}`}
      >
        {children}
      </div>
      <button
        className="modal-close is-large"
        aria-label="close"
        onClick={onClose}
      ></button>
    </div>
  );
};
