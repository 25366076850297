import React from "react";
 import { ZoneCrud } from "core/features/zoneData/zoneCrud";

export const ZoneAction = () => {
  return (
    <React.Fragment>
      {/* <div className="content-container__header">
        <PageHeader
          header={"company"}
          description={"Interface for handling companies"}
        />
        <BreadcrumbContainer>
          <BreadcrumbItem label={"Home"} link="/company/dashboard"/>
          <BreadcrumbItem label={"Company"} active={true} />
        </BreadcrumbContainer>
      </div> */}
      {/* /.content__header */}
      <div className="content-container__body">
        <div className="dashboard">
          <div className="dashboard__tab-content">
            < ZoneCrud />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
