import _ from "lodash";
import Joi from "joi";
import { toast } from "react-toastify";
import moment from "moment";
import { generateReport } from "./reportService";
import { useLoading } from "core/hooks/loadingHook";
import { useSubmitForm } from "core/hooks/submitFormHook";
import { downloadReport } from "core/utility/util";
import { FormCard } from "core/cards/formCard";
import { EditTextField } from "core/components/inputWithAccessControl";
import { SelectContainer } from "core/components/inputWithAccessControl";
import monthList from "./month.json";
import { addDefaultOption } from "core/utility/util";
import { formatArrayToOptions } from "core/utility/util";
import { useMasterListState } from "../masterList/masterListProvider";
import { SearchableSelectContainer } from "core/components/inputWithAccessControl";
import { DateField } from "core/components/inputWithAccessControl";
import { DateTimeField } from "core/components/inputWithAccessControl";

export const CumulativeActionReport = () => {
    const { loaderContainer, startLoading, stopLoading } = useLoading();

    const defaultInputs = {};
    const submitCallback = async () => {
        startLoading();
        const { fromDate, toDate } = inputs;
        const fromUnix = moment(fromDate).format('YYYY-MM-DDTHH:mm:ss');
        const toUnix = moment(toDate).add(moment.duration("23:59:59")).format('YYYY-MM-DDTHH:mm:ss');
        const body = {
            templateName: "lorawan_cumulative_action_report",
            reportName: "Cumulative_Action_Report",
            exportFormat: "pdf",
            input: {
                fromDate: fromUnix,
                toDate: toUnix,
            },
        };
        // console.log(body);
        try {
            const response = await generateReport(body);
            let fileName = response.headers["x-blob-file"];
            downloadReport(fileName, response);
            toast.success("Report generated successfully");
            stopLoading();
        } catch (error) {
            console.log(error);
            toast.error("Error while generating report");
            stopLoading();
        }
    };
    const schema = Joi.object({
        fromDate: Joi.date().label("From Date").required(),
        toDate: Joi.date().label("To Date").required(),
    });
    const { inputs, errors, handleInputChange, handleSubmit, resetInput } =
        useSubmitForm(schema, submitCallback, defaultInputs);
    const accessToEdit = true;
    return (
        <div className="card mx-2 my-2">
            <FormCard
                formName={"Cumulative Action Report"}
                onSubmit={handleSubmit}
                submitAccess={true}
            >
                <div className="columns is-multiline">

                    <div className="column is-one-third">
                        <DateField
                            identifier="fromDate"
                            labelName="From Date"
                            step={5}
                            editAccess={accessToEdit}
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                        />
                    </div>
                    {/* /.column */}

                    <div className="column is-one-third">
                        <DateField
                            identifier="toDate"
                            labelName="To Date"
                            handleInputChange={handleInputChange}
                            inputs={inputs}
                            errors={errors}
                            editAccess={true}
                        />
                    </div>
                    {/* /.column */}


                </div>
                {/* /.columns */}
            </FormCard>
            {loaderContainer}
        </div>
    );
};
