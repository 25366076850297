import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";



export function getDefaultReadings(companyId, from, to) {
  const base = globalConfig.get().apiEndpoint;
  return http.post(`${base}/nodes/diagnostics/me`, {
    companyId,
    from,
    to
  });
}

export function getAssignedParametersByCompany(companyId) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes/diagnostics/parameters/${companyId}`);
}

export function getLiveReadingsByCompany(companyId) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes/liveReadings/${companyId}`);
}

export function searchNode(companyId) {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/nodes/search/company/${companyId}`);
}

