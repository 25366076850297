import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { CompanyTable } from "./companyTable";
import { NodeModal } from "./nodeModal";
 
export const CompanyCrud = () => {
  const [Companies, setCompanies] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  
  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    // console.log(selectedId);
    setSelectedInput(data);
    openNodeModal();
  };

   const [nodeModal, openNodeModal, closeNodeModal] = useModal(false);

 

  return (
    <React.Fragment>
 
      <CompanyTable
        data={Companies}
        onEdit={handleEdit}
       />
        {nodeModal ? (
        <div>
          <NodeModal
            open={nodeModal}
            onClose={closeNodeModal}
            selectedData={selectedInput}
          />
        </div>
      ) : (
        ""
      )}
 
     
    </React.Fragment>
  );
};
