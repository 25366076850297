import { globalConfig } from "core/configuration/config";
import http from "core/service/httpService";

// export function fetchAllCompany() {
//   const base = globalConfig.get().apiEndpoint;
//   return http.get(`${base}/companies`);
// }

// export function fetchRecentDailyConsumptionByCompany(companyId, from, to) {
//   const base = globalConfig.get().apiEndpoint;
//   return http.post(`${base}/companies/consumption`, {
//     companyId,
//     from,
//     to
//   });
// }

// export function fetchOnlineCompanyCount() {
//   const base = globalConfig.get().apiEndpoint;
//   return http.get(`${base}/companies/online`);
// }
 
export function fetchAllZone() {
    const base = globalConfig.get().apiEndpoint;
    return http.get(`${base}/zones`);
  }