import React, { Component } from "react";

export const ParameterCardV2 = ({ parameter, currentReading }) => {
  // console.log("current raeding :", currentReading);
  // console.log("parameter :", parameter);

  const {
    name,
    lowerLimit,
    upperLimit,
    unit,
    displayName,
  } = parameter;
  const normalizedName = name.replace(/_/g, ' ');

  return (
    <div className="parameter-card-2">
      <div className="parameter-card-2__name">{normalizedName}</div>
      <div className="parameter-card-2__value">{`${currentReading} ${unit}`}</div>
      <div className="parameter-card-2__standard">
        {`Approved Limit : ${lowerLimit} - ${upperLimit} ${unit}`}
      </div>
      {/* <div className="parameter-card-2__range">{`Range : ${rangeMin} - ${rangeMax} ${unit}`}</div> */}
    </div>
  );
};
