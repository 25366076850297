import { Carousel, CarouselSlide } from "core/components/carousel";
import { ParameterCardV2 } from "./parameterCardV2";
import _ from "lodash";

const ParameterCarouselSlide = ({ parameter, currentReading = "NA" }) => {
  return (
    <CarouselSlide>
      <ParameterCardV2 parameter={parameter} currentReading={currentReading} />
    </CarouselSlide>
  );
};

export const ParametersCarouselCard = ({ parameters, readingMap }) => {
  if (parameters == undefined || parameters.length == 0) {
    return "";
  }
  console.log(readingMap);
  const carouselSlides = [];
  const counter = 1;
  _.forEach(parameters, (parameter) => {
    const { identifier } = parameter;
    const lowercaseIdentifier = identifier.toLowerCase();
    // console.log("identifier in card :", identifier)
    // console.log("parameters in card :", parameters);
    const currentReading = parseFloat(readingMap[lowercaseIdentifier]);
    const roundedReading = Number(currentReading.toFixed(2));
    // console.log("reading in carousel", currentReading)
    // console.log("reading map in card :", readingMap);
    carouselSlides.push(
      <ParameterCarouselSlide
        key={identifier}
        parameter={parameter}
        currentReading={roundedReading}
      />
    );
  });

  let carousels = "";
  // console.log(carouselSlides);
  if (carouselSlides.length > 1) {
    return <Carousel>{carouselSlides}</Carousel>;
  } else {
    return carouselSlides[0];
  }
  return carousels;
};
