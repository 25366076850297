import { EditIcon } from "core/components/svgIcon";
import { Table } from "core/components/table";
import { useModal } from "core/hooks/modalHook";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { fetchAllZone } from "./zoneService";
import { useLoading } from "core/hooks/loadingHook";

export const ZoneTable = ({ onEdit }) => {
  const { loaderContainer, startLoading, stopLoading } = useLoading("absolute");
  const [data, setData] = useState([]);


  const handleEditAction = (value) => {
    onEdit(value);
  };

  // const ActionButtonRenderer = ({ value }) => {
  //   return (
  //     // <React.Fragment>
  //     //   <button
  //     //     className="button is-small is-primary has-text-weight-medium my-1 mt-2"
  //     //     onClick={() => handleEditAction(value)}
  //     //   >
  //     //     {/* <EditIcon /> */}
  //     //     Details
  //     //   </button>
  //     // </React.Fragment>
  //   );
  // };

  const columnDefs = [


    {
      headerName: "Name",
      field: "name",
      maxWidth: 300,
    },
    {
      headerName: "Area",
      field: "area",
      minWidth: 100,
    },
    // {
    //   headerName: "Details",
    //   minWidth: 150,
    //   floatingFilter: false,
    //   valueGetter: function (params) {
    //     return params.data;
    //   },
    //   cellRenderer: ActionButtonRenderer,
    //   cellRendererParams: {},
    // },
  ];

  // const columnDefs = [
  //   {
  //     // headerName: "Company",
  //     children: children,
  //   },
  // ];

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const { data: zones } = await fetchAllZone();
        const data = zones;
        setData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      stopLoading();
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="card mx-2 my-2 ">
        <Table
          rowData={data}
          columnDefs={columnDefs}
          pagination={true}
          theme="alpine"
        />
      </div>

      {loaderContainer}
    </React.Fragment>
  );
};
