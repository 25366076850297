import React, { useEffect, useState } from "react";
import _ from "lodash";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Label,
    Legend,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { getMonthlyConsumptionData } from "./companyService";

const BarChartContainer = ({ data, barWidth }) => {
    return (
        <BarChart
            width={barWidth}
            height={350}
            data={data}
            margin={{
                top: 5, right: 30, left: 20, bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month">
                {/* <Label value="Month" position="insideBottom" offset={0} /> */}
            </XAxis>
            <YAxis>
                {/* <Label value="Consumption" position="topLeft" offset={50} /> */}

            </YAxis>
            <Tooltip />
            <Legend />
            <Bar dataKey="consumption" fill="#8884d8" />
            {/* <Bar dataKey="pv" fill="#82ca9d" /> */}
        </BarChart>
    );
};

const MonthlyConsumptionChart = (props) => {
    const { selectedData } = props;
    const [data, setData] = useState([]);
    const [hideChart, setHideChart] = useState(true);
    const [barChartWidth, setBarChartWidth] = useState(1000); // Default width

    useEffect(() => {
        const fetchData = async () => {
            if (selectedData) {
                try {
                    const { data: consumptionList } = await getMonthlyConsumptionData(selectedData["id"]);
                    setData(consumptionList);
                    if (consumptionList.length > 0) {
                        setHideChart(false);
                        const calculatedWidth = Math.min(1000, consumptionList.length * 250);
                        setBarChartWidth(calculatedWidth);
                        console.log(barChartWidth);
                    }
                    console.log("consumption", consumptionList);
                } catch (error) {
                    console.error("Error fetching monthly consumption data:", error);
                }
            }
        };

        fetchData();
    }, [selectedData]);
    return (
        <div>
            {!hideChart &&
                <BarChartContainer
                    data={data}
                    barWidth={barChartWidth}
                />}

        </div>

    );
};

export default MonthlyConsumptionChart;
