import React from "react";
import { toast } from "react-toastify";
import _ from "lodash";
import { CapitalizedText } from "core/components/capitalizedText";
import { useAlertModalState } from "core/features/errorDisplay/alertModalProvider";

export const useAxiosErrorHandlingHook = () => {
  const showMessage = useAlertModalState();
  const showError = (
    error,
    toastMessage = "Request could not be processed"
  ) => {
    if (error.response && error.response.data && error.response.data.message) {
      if (_.size(error.response.data.message) <= 50) {
        toast.error(<CapitalizedText message={error.response.data.message} />);
      } else {
        toast.error(<CapitalizedText message={toastMessage} />);
        showMessage(error.response.data.message);
      }
    } else if (error.response) {
      toast.error(<CapitalizedText message={toastMessage} />);
      showMessage(error.response);
    } else {
      toast.error(<CapitalizedText message={toastMessage} />);
      showMessage(_.toString(error));
      console.log(error);
    }
  };

  return {
    showError,
  };
};
