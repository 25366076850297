import React, { useState } from "react";
import _ from "lodash";
import roles from "roles.json";
import { addDefaultOption } from "core/utility/util";
import { useModal } from "core/hooks/modalHook";
import { ZoneTable } from "./zoneTable";
  
export const ZoneCrud = () => {
  const [Zones, setZones] = useState([]);
  const [action, setAction] = useState("");
  const [selectedInput, setSelectedInput] = useState();
  const [selectedId, setSelectedId] = useState("");
  
  // Edit Action
  const handleEdit = (data) => {
    setAction("EDIT");
    setSelectedId(data["id"]);
    // console.log(selectedId);
    setSelectedInput(data);
    openNodeModal();
  };

   const [  openNodeModal, closeNodeModal] = useModal(false);

 

  return (
    <React.Fragment>
 
      <ZoneTable
        data={Zones}
        onEdit={handleEdit}
       />

 
     
    </React.Fragment>
  );
};
