import React from 'react'
import { useMasterListState } from '../masterList/masterListProvider'

export const OfflineCard = () => {
    const { companyList, onlineCount } = useMasterListState();
    const offlineCount = companyList.length - onlineCount["count"];
    return (
        <div class="serviceBox">
            <div class="service-content">
                <div class="service-icon">
                    <span>
                        <img class="img-size" src={process.env.PUBLIC_URL + '/cardImages/disconnected.png'} />
                    </span>
                </div>
                <h3 class="title">Offline Connections</h3>
                <p class="description">{offlineCount}</p>

            </div>
        </div>
    )
}

