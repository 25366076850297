import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useLoading } from "core/hooks/loadingHook";
import React, { useEffect, useRef, useState } from "react";
import { fetchGuestTokenForDashboard } from "./dashboardService";

export const Dashboard = () => {
  const child1 = useRef(null);
  const delay = ms => new Promise(res => setTimeout(res, ms));
  const { loaderContainer, startLoading, stopLoading } = useLoading("absolute");

  const fetchCustomGuestToken = async () => {
    try {
      // Fetch the guest token
      const tokenPayload = {
        resources: [
          {
            id: "ff93afd1-3792-4e36-8964-ca3d9e95f0b9",
            type: "dashboard",
          },
        ],
        rls: [
          {
            dataset: 1,
            clause: ""
          },
        ],
        user: {
          first_name: "AMR DASHBOARD",
          last_name: "AMR DASHBOARD",
          username: "test",
        }
      };
      const { data } = await fetchGuestTokenForDashboard(tokenPayload);
      const newToken = data.body.token;
      console.log("token", newToken);
      await delay(1000);
      return newToken;


    } catch (error) {
      console.error("Error fetching data or embedding dashboard:", error);
    }
  };

  useEffect(() => {
    // Embed the dashboard with the new token
    startLoading();
    embedDashboard({
      id: "ff93afd1-3792-4e36-8964-ca3d9e95f0b9", // Given by the Superset embedding UI
      supersetDomain: "https://dashboard.amr.reconindia.in",
      mountPoint: child1.current,
      fetchGuestToken: () => fetchCustomGuestToken(),
      dashboardUiConfig: {
        hideTitle: false, hideTab: true, showControls: false,
        filters: {
          expanded: false,
        }
      },
    });
    stopLoading();
  }, []);

  return (
    <div className="App">
      <div className="superset-container" ref={child1}></div>
      {loaderContainer}
    </div>
  );
};
