import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { getAssignedFrontendPermissions } from "./userService";

const PermissionStateContext = React.createContext();

function PermissionProvider({
  children,
  initialPermissions = ["DASHBOARD_DEFAULT"],
}) {
  // const [permissions, setPermissions] = React.useState(["Akshay", "Sathe"]);
  const [permissions, setPermissions] = React.useState(initialPermissions);
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const { data: permissions } = await getAssignedFrontendPermissions();
        // console.log("getAssignedFrontendPermissions() ", permissions);
        // setPermissions(permissions);
      } catch (ex) {
        console.log(ex);
        toast.error("Error while fetching data");
      }
    };
    fetchData();
  }, []);

  return (
    <PermissionStateContext.Provider value={permissions}>
      {/* <PermissionDispatchContext.Provider value={setPermissions}> */}
      {children}
      {/* </PermissionDispatchContext.Provider> */}
    </PermissionStateContext.Provider>
  );
}

function usePermissionState() {
  const context = React.useContext(PermissionStateContext);
  if (context === undefined) {
    throw new Error(
      "usePermissionState must be used within a PermissionProvider"
    );
  }
  return context;
}

// function usePermissionDispatchState() {
//   const context = React.useContext(PermissionDispatchContext);
//   if (context === undefined) {
//     throw new Error(
//       "usePermissionDispatchState must be used within a PermissionProvider"
//     );
//   }
//   return context;
// }

export { PermissionProvider, usePermissionState };
