import React from "react";

import { UpdatePassword } from "core/features/user/updatePassword";
import { useMasterListState } from "core/features/masterList/masterListProvider";

export const UserProfile = (props) => {
  const masterList = useMasterListState();
  const user = masterList["user"];
  // console.log(user)

  return (
    <div className="container is-fullhd">
      <div className="card mx-2 my-4">
        <header className="card-header report-header">
          <span className="card-header-title">
            <h5 className="is-size-6 has-text-weight-bold ">User Details</h5>
          </span>
        </header>
        <div className="card-content">
          <div className="content">
            <h6 className="is-size-6 "><span className="has-text-weight-semibold"> User Name : </span> {user.name} </h6>
            <h6 className="is-size-6"><span className="has-text-weight-semibold "> Email : </span>{user.email}</h6>
            <h6 className="is-size-6"><span className="has-text-weight-semibold"> Contact : </span> {user.contactNumber}</h6>
          </div>
        </div>
        {/* /.card-content */}
      </div>
      {/* /.card */}
      <div className="mx-2">
        <UpdatePassword {...props} />
      </div>
    </div>
  );
};
