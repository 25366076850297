import React, { useState, useEffect } from "react";
import { getLiveReadingsByCompany } from "./nodeService";

export const useLiveReadingHook = (companyId) => {
  const [liveReadings, setLiveReadings] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {

        const { data: liveReadings } = await getLiveReadingsByCompany(companyId);
        // console.log("live readings", liveReadings);
        setLiveReadings(liveReadings);

      } catch (error) {
        console.log("Could not fetch live readings");
        console.log(error);
      }
    };
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);
  // console.log("live readings :", liveReadings);

  return [liveReadings];
};
