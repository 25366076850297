import React, { Component, useState } from "react";
import { useSubmitForm } from "core/hooks/submitFormHook";
import Joi from "joi";
import { EditTextField } from "core/components/inputWithAccessControl";
import { toast } from "react-toastify";
import { useLoading } from "core/hooks/loadingHook";
import { CapitalizedText } from "core/components/capitalizedText";
import {
  login,
  saveToken,
  saveRefreshToken,
} from "core/features/authentication/authService";
import roles from "roles.json";
import jwt_decode from "jwt-decode";
import _ from "lodash";
import { useAxiosErrorHandlingHook } from "core/features/errorDisplay/axiosErrorHandlingHook";
import { saveUser } from "core/features/authentication/authService";
// import roles from "roles.json";

export const Login = (props) => {
  const schema = Joi.object({
    username: Joi.string().label("Login Id").trim().min(3).max(30).required(),
    password: Joi.string().label("Password").trim().min(3).max(30).required(),

  });
  const { showError } = useAxiosErrorHandlingHook();

  const loginCallback = async () => {
    startLoading("Checking credentials....");
    try {
      const { username, password } = inputs;
      // const response = await login(username, password);
      // saveToken(response.headers["x-authorization"]);
      const response = await login(username, password);
      // console.log(username);
      saveToken(response.data.accessToken);
      // console.log(username);
      saveRefreshToken(response.data.refreshToken);
      const decodedToken = jwt_decode(response.data.accessToken);
      // console.log(decodedToken);
      const role = decodedToken.role;
      saveUser(JSON.stringify(response.data));
      stopLoading()

      if (_.includes(role, roles.USER_ROLE)) {
        window.location = "/company";
        toast.success("Logged In Successfully");

      }
    } catch (error) {
      const toastMessage = "Error while logging in";
      showError(error, toastMessage);
      stopLoading();
    }
  };

  const { inputs, errors, handleInputChange, handleSubmit } = useSubmitForm(
    schema,
    loginCallback
  );

  // const [loading, setLoading] = useState(false);
  const { loaderContainer, startLoading, stopLoading } = useLoading();
  return (
    <div className="login">
      <div className="login__body">
        <div className="login-card">
          <form onSubmit={handleSubmit}>
            <h1 className="login-card__header title">Welcome to AMR Dashboard</h1>
            <div className="login-card__body has-text-centered">
              <p className="subtitle login-card__body__subtitle">
                Sign in to start your session
              </p>
              <EditTextField
                identifier="username"
                labelName="Login Id"
                handleInputChange={handleInputChange}
                inputs={inputs}
                errors={errors}
                editAccess={true}
              />
              <EditTextField
                identifier="password"
                labelName="Password"
                type="password"
                handleInputChange={handleInputChange}
                inputs={inputs}
                errors={errors}
                editAccess={true}
              />
            </div>
            {/* /.login-card__body */}
            <div className="login-card__footer has-text-centered">
              <button className="button is-primary is-right" type="submit">
                Sign In
              </button>
            </div>
            {/* /.login-card__footer */}
          </form>
        </div>
        {/* /.login-card */}
      </div>
      {/* /.login__body */}
      {/* 
      <div className="login__footer">
        &copy; 2021 VAA Technologies Pvt. Ltd.
      </div> */}
      {/* /.login__footer */}
      {loaderContainer}
      {/* <LoaderContainer loading={loading} /> */}
    </div>
  );
};
