import React from 'react'
import { useMasterListState } from '../masterList/masterListProvider';

export const OnlineCard = () => {

    const { onlineCount } = useMasterListState();
    const count = onlineCount["count"];

    return (
        <div class="serviceBox">
            <div class="service-content">
                <div class="service-icon">
                    <span>
                        <img class="img-size" src={process.env.PUBLIC_URL + '/cardImages/connected.png'} />
                    </span>
                </div>
                <h3 class="title">Online Connections</h3>
                <p class="description">{count}</p>

            </div>
        </div>
    )
}

