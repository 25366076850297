import React from 'react'
import { useMasterListState } from '../masterList/masterListProvider'

export const ConnectionCard = () => {
    const { companyList } = useMasterListState();
    const connectionCount = companyList.length;
    return (
        <div class="serviceBox">
            <div class="service-content">
                <div class="service-icon">
                    <span>
                        <img class="img-size" src={process.env.PUBLIC_URL + '/cardImages/connection.png'} />
                    </span>
                </div>
                <h3 class="title">Total Connections</h3>
                <p class="description">{connectionCount}</p>

            </div>
        </div>
    )
}

