import React from "react";
import PropTypes from "prop-types";

export const FormCardButton = ({ text, type = "success", action }) => {
  const classNameType = {
    success: "is-primary has-text-weight-medium",
    error: "is-danger",
    info: "is-info",
  };

  const className = classNameType[type];
  return (
    <p className="control">
      <button
        color="white"
        type="button"
        className={`button is-small ${className}`}
        onClick={action}
      >
        {text}
      </button>
    </p>
  );
};

export const FormCard = (props) => {
  const {
    formName,
    onSubmit,
    children,
    onFormReset,
    onReject = false,
    onSave,
    onDelete,
    options = {},
    submitAccess = false,
    saveAccess = false,
  } = props;

  let additionalButtonHtml = "";
  const { buttons } = options;
  if (buttons) {
    additionalButtonHtml = buttons;
  }

  let buttonHtml = "";
  let footerButtonHtml = "";
  let returnButtonHtml = "";
  let submitButtonHtml = "";
  let saveButtonHtml = "";
  let deleteButtonHtml = "";

  if (onReject) {
    returnButtonHtml = (
      <FormCardButton text={"Return/Reject"} action={onReject} type={"error"} />
    );
  } else {
    if (onDelete) {
      deleteButtonHtml = (
        <FormCardButton text={"Delete"} action={onDelete} type={"error"} />
      );
    }
    submitButtonHtml = (
      <FormCardButton text={"Submit"} action={onSubmit} type={"success"} className="report-button" />
    );
    saveButtonHtml = (
      <FormCardButton text={"Save"} action={onSave} type={"info"} />
    );
  }

  if (submitAccess) {
    buttonHtml = (
      <span className="card-header-icon">
        <div className="field is-grouped">
          {additionalButtonHtml}
          {returnButtonHtml}
          {submitButtonHtml}
          {deleteButtonHtml}
          {/* {saveButtonHtml} */}
          {/* <p className="control">
            <button
              type="button"
              className="button is-small"
              onClick={onFormReset}
            >
              Clear
            </button>
          </p> */}
          {/* <p className="control">
            <button type="submit" className="button is-success is-small">
              Submit
            </button>
          </p> */}
        </div>
      </span>
    );
    footerButtonHtml = (
      <div className="card-footer-item">
        <div className="field is-grouped">
          {additionalButtonHtml}
          {returnButtonHtml}
          {submitButtonHtml}

          {/* {saveButtonHtml} */}
          {/* <p className="control">
            <button
              type="button"
              className="button is-small"
              onClick={onFormReset}
            >
              Clear
            </button>
          </p> */}
          {/* <p className="control">
            <button type="submit" className="button is-success is-small">
              Submit
            </button>
          </p> */}
        </div>
      </div>
    );
  }

  if (saveAccess) {
    buttonHtml = (
      <span className="card-header-icon">
        <div className="field is-grouped">
          {additionalButtonHtml}
          {returnButtonHtml}
          {submitButtonHtml}
          {saveButtonHtml}
          {deleteButtonHtml}
        </div>
      </span>
    );
    footerButtonHtml = (
      <div className="card-footer-item">
        <div className="field is-grouped">
          {additionalButtonHtml}
          {returnButtonHtml}
          {submitButtonHtml}
          {saveButtonHtml}
          {deleteButtonHtml}
        </div>
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="card card-full-height report-card">
        <form onSubmit={onSubmit}>
          <header className="card-header report-header">
            <p className="card-header-title is-size-6">{formName}</p>
          </header>
          {/* /.card-header */}
          <div className="card-content">{children}</div>
          <footer className="card-footerc report-footer">{footerButtonHtml}</footer>
          {/* /.card-content */}
        </form>
      </div>
    </React.Fragment>
  );
};

FormCard.propTypes = {
  formName: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onFormReset: PropTypes.func,
  submitAccess: PropTypes.bool,
  saveAccess: PropTypes.bool,
  deleteAccess: PropTypes.bool,
};
