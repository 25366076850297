// import config from "config.json";
import http from "core/service/httpService";
import { globalConfig } from "core/configuration/config";

export function updatePassword(password) {
  // console.log(temp);
  const base = globalConfig.get().apiEndpoint;
  // const base = window.configs.apiEndpoint;
  return http.put(`${base}/users/updatePassword`, {
    password: password,
  });
}

export function getLoggedInUserDetails() {
  const base = globalConfig.get().apiEndpoint;
  return http.get(`${base}/users/me`);
}
