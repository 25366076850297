import React, { Component } from "react";
import bulmaCarousel from "bulma-carousel/dist/js/bulma-carousel.min.js";
// Carousel wrapper component
export const CarouselSlide = ({ children }) => {
  return <div className="item">{children}</div>;
};

export const Carousel = ({ children, options }) => {
  // Initialize all elements with carousel class.
  if (options == null) {
    options = { autoplay: true, infinite: true };
  }
  const carousels = bulmaCarousel.attach(".carousel", options);
  return (
    <div id="carousel-demo" className="carousel carousel--hidden">
      {children}
    </div>
  );
};
