import React from "react";
import _ from "lodash";

export const CapitalizedText = (props) => {
  const { message } = props;

  if (_.isArray(message)) {
    const messages = _.map(message, (m) => {
      return (
        <li key={m}>
          <span className="capitalize">{m}</span>
        </li>
      );
    });
    return <ul class="demo">{messages}</ul>;
  } else {
    return <span className="capitalize">{message}</span>;
  }
};
