import React from "react";
import "./App.scss";
import { ToastContainer } from "react-toastify";
import {
  Route,
  Navigate,
  Routes,
  BrowserRouter as Router,
} from "react-router-dom";
import { AlertModalProvider } from "core/features/errorDisplay/alertModalProvider";
import { Login } from "views/login";
import { Company } from "views/company/company";

function App() {
  return (
    <React.Fragment>
      <AlertModalProvider>
        <ToastContainer theme="dark" />
        <Router>
          <Routes>
          <Route path="/company/*" element={<Company />} />
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
          </Routes>
        </Router>
      </AlertModalProvider>
    </React.Fragment>
  );
}

export default App;
